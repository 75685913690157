import React, { useState, useEffect } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { fromLonLat, toLonLat } from "ol/proj";
import Overlay from "ol/Overlay";

const MapComponent = ({ setlat, setlng, lat, lng }) => {
    const [map, setMap] = useState(null); // Quản lý bản đồ
    const [markerOverlay, setMarkerOverlay] = useState(null); // Quản lý overlay marker

    useEffect(() => {
        // Tạo bản đồ khi component mount
        const newMap = new Map({
            target: "map", // Phần tử DOM có ID "map"
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
                    }),
                }),
            ],
            view: new View({
                center: fromLonLat([lng || 107.577457, lat || 16.461786]), // Vị trí trung tâm được thiết lập từ lat và lng
                zoom: 10,
            }),
        });

        // Tạo overlay marker
        const marker = document.createElement("div");
        marker.style.width = "32px"; // Kích thước icon
        marker.style.height = "32px";
        marker.style.backgroundImage =
            "url('https://cdn.jsdelivr.net/npm/leaflet@1.9.4/dist/images/marker-icon.png')";
        marker.style.backgroundSize = "contain"; // Đảm bảo ảnh không bị cắt
        marker.style.backgroundRepeat = "no-repeat";
        marker.style.transform = "translate(-50%, -100%)"; // Vị trí icon chính xác
        marker.style.position = "absolute";

        const overlay = new Overlay({
            element: marker,
            positioning: "bottom-center",
            stopEvent: false,
        });

        newMap.addOverlay(overlay);

        setMap(newMap);
        setMarkerOverlay(overlay);

        return () => {
            newMap.setTarget(null); // Cleanup bản đồ khi unmount
        };
    }, []);

   useEffect(() => {
        if (map && markerOverlay) {
            // Lắng nghe sự kiện click
            map.on("singleclick", (event) => {
                const coordinates = toLonLat(event.coordinate);
                const [newLng, newLat] = coordinates;

                setlat(newLat);
                setlng(newLng);

                // Cập nhật vị trí marker
                markerOverlay.setPosition(event.coordinate);

            });

            // Cập nhật vị trí marker ban đầu
            if (lat && lng) {
                markerOverlay.setPosition(fromLonLat([lng, lat]));
            } else if (map) {
                // Nếu không có tọa độ ban đầu từ props, đặt lại marker ở tọa độ mặc định
                markerOverlay.setPosition(fromLonLat([107.577457, 16.461786]));
            }
        }
    }, [map, markerOverlay, lat, lng, setlat, setlng]);

    return (
        <div id="map" style={{ width: "100%", height: "400px" }}></div>
    );
};

export default MapComponent;
