import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ApiUrl } from '../../../api/api-url';

//--- Styles
import "../landslide.scss";

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType, APIUrlDefault, MaxSizeImageUpload, } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import SunEditor from "suneditor-react";
//import dialog from "suneditor/src/plugins/modules/dialog";
import "suneditor/dist/css/suneditor.min.css";
import MapComponent from "../map.view";
//--- Action
import * as landslideAction from "../../../redux/store/landslide/landslide.store";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

export default function AddLandslide(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        onSuccess,
        GetListAll,
        rowsPerPage,
        setOrder,
        setOrderBy,
        isQHT, isQHCC, isQHHTKT
    } = props;

    const [placeName, setplaceName] = useState();
    const [lat, setlat] = useState();
    const [lng, setlng] = useState();
    const [surveyer, setsurveyer] = useState();
    const [cause, setCause] = useState();
    const [severity, setSeverity] = useState();
    const [isShow, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });


    const onSubmit = (data) => {
        if (!data) {
            return;
        }
        let formData = new FormData();
        formData.append("placeName", placeName);
        // formData.append("lat", lat.toString().replace('.',','));
        // formData.append("lng", lng.toString().replace('.',','));
        formData.append("lat", lat.toString().replace(',','.'));
        formData.append("lng", lng.toString().replace(',','.'));
      formData.append("surveyer",surveyer);
        formData.append("cause", cause);
       formData.append("severity", severity);
        // if (files && files.length > 0)
        //     formData.append("documentUploadId", files[0].fileId);


        landslideAction
            .CreateLandslide(formData)
            .then((result) => {
                if (result) {
                    setOrder("desc");
                    setOrderBy("modifiedDate");
                    GetListAll(undefined, undefined, undefined, undefined, undefined, undefined, 1, rowsPerPage, undefined);
                    onSuccess();
                    ShowNotification(
                        "Thành công",
                        NotificationMessageType.Success
                    );
                }
            })
            .catch((err) => {
                onSuccess();
                err.errorMessage && ShowNotification(
                    err.errorMessage,
                    NotificationMessageType.Error
                )
            });
    };

    const onLocationSelect = (lat, lng) => {
        setlat(lat);
        setlng(lng);
    };


    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Thêm tin tức</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">
                        <div className="form-group">
                                <label className="text-dark">
                                    Khu vực xảy ra<span className="required"></span>
                                </label>
                                <TextField
                                    name="placeName"
                                    error={errors.placeName && errors.placeName.type === "required"}
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) =>
                                        setplaceName(e.target.value)
                                    }
                                />
                                {errors.placeName && errors.placeName.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                            </div>
                            <div className="form-group" >
                                <label className="text-dark">
                                    Latitude<span className="required"></span>
                                </label>
                                <TextField
                                    name="lat"
                                    error={errors.lat && errors.lat.type === "required"}
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) =>
                                        setlat(e.target.value)
                                    }
                                    value={lat}
                                />
                                {errors.lat && errors.lat.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                            </div>
                            <div className="form-group" >
                                <label className="text-dark">
                                    Longtitude<span className="required"></span>
                                </label>
                                <TextField
                                    name="lng"
                                    error={errors.lng && errors.lng.type === "required"}
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) =>
                                        setlng( e.target.value)
                                    }
                                    value={lng}
                                />
                                {errors.lng && errors.lng.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="text-dark">
                                    Nội dung
                                </label>
                                <TextField
                                    name="surveyer"
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                        setsurveyer( e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-dark">
                                    Mức độ 
                                </label>
                                <TextField
                                    name="severity"                                    
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                        setSeverity( e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-dark">
                                    Nguyên nhân
                                </label>
                                <TextField
                                    name="cause"
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                        setCause( e.target.value)
                                    }
                                />
                            </div>
                            <MapComponent setlat={setlat} setlng={setlng} onLocationSelect={onLocationSelect} />

                    </DialogContent>

                    <DialogActions className="border-top">
                        <Button
                            type="submit"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </div>
    );
}
